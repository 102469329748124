import { appointmentStatus } from '../globals/global';

export const getGenderEN = (gender: string) => {
    switch (gender) {
        case 'Männlich':
            return 'male';
        case 'Weiblich':
            return 'female';
        case 'Divers':
            return 'divers';
        case 'Unbekannt':
            return 'unknown';
        case '':
            return 'unknown';
        default:
            return gender;
    }
};

export const getGenderDE = (gender: string) => {
    switch (gender) {
        case 'male':
            return 'Männlich';
        case 'female':
            return 'Weiblich';
        case 'divers':
            return 'Divers';
        case 'unknown':
            return 'Unbekannt';
        case '':
            return '';
        default:
            return gender;
    }
};

export const getCountryEN = (country: string) => {
    switch (country) {
        case 'Deutschland':
            return 'Germany';
        case 'Österreich':
            return 'Austria';
        case 'Schweiz':
            return 'Switzerland';
        default:
            return '';
    }
};

export const getCountryDE = (country: string) => {
    switch (country) {
        case 'Germany':
            return 'Deutschland';
        case 'Austria':
            return 'Österreich';
        case 'Switzerland':
            return 'Schweiz';
        default:
            return '';
    }
};

export const getCountryLongName = (countryShortName: string) => {
    switch (countryShortName) {
        case 'D':
            return 'Deutschland';
        case 'DE':
            return 'Deutschland';
        case 'A':
            return 'Österreich';
        case 'AT':
            return 'Österreich';
        case 'CH':
            return 'Schweiz';
        case 'LU':
        case 'L':
            return 'Luxemburg';
        case 'IT':
        case 'I':
            return 'Italien';
        case 'FR':
        case 'F':
            return 'Frankreich';
        case 'ES':
        case 'E':
            return 'Spanien';
        case 'NL':
            return 'Niederlande';
        case 'HU':
        case 'H':
            return 'Ungarn';
        case 'DK':
            return 'Dänemark';
        default:
            return '';
    }
};

export const translateStatus = (status: string) => {
    switch (status) {
        case 'cancelled':
            return 'abgesagt';
        case 'pending':
            return 'geplant o. Uhrzeit';
        case 'booked':
            return 'geplant';
        case 'arrived':
            return 'aktuell';
        case 'fulfilled':
            return 'abgeschlossen';
        case appointmentStatus.enteredInError:
            return 'unvollständig';
        default:
            break;
    }
};

/**
 * format date from yyyy-MM-dd or yyyy.MM.dd to dd.MM.yyyy
 *
 * @param date
 *      the date as string
 * @returns
 *      the date with new format
 */
export const getGermanDateFormatFromString = (date: string) => {
    const day = date.slice(8);
    const month = date.slice(5, -3);
    const year = date.slice(0, -6);
    return day + '.' + month + '.' + year;
};

export const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/;

export const isEmailValid = (email: string) => {
    return pattern.test(String(email).toLowerCase());
};

export const getBasicAuthBase64Encoded = (basicauth: string) => {
    const base64encodedStr = btoa(basicauth);
    const basicAuthBase64 = 'Basic ' + base64encodedStr;
    return basicAuthBase64;
};

export const getDataFromAppProperties = (propRes: any) => {
    const appProps = {
        Appointment: {
            skipBehandlersuche:
                propRes.Appointment?.skipBehandlersuche !== undefined ? propRes.Appointment?.skipBehandlersuche : false,
        },
        AppointmentSummary: {
            behandler:
                propRes.AppointmentSummary?.behandler !== undefined ? propRes.AppointmentSummary?.behandler : true,
            fach: propRes.AppointmentSummary?.fach !== undefined ? propRes.AppointmentSummary?.fach : true,
            pflege: propRes.AppointmentSummary?.pflege !== undefined ? propRes.AppointmentSummary?.pflege : true,
            raum: propRes.AppointmentSummary?.raum !== undefined ? propRes.AppointmentSummary?.raum : true,
            standort: propRes.AppointmentSummary?.standort !== undefined ? propRes.AppointmentSummary?.standort : true,
        },
        RegistrationMandFields: {
            birthDate:
                propRes.RegistrationMandFields?.birthDate !== undefined
                    ? propRes.RegistrationMandFields.birthDate
                    : null,
            city: propRes.RegistrationMandFields?.city !== undefined ? propRes.RegistrationMandFields.city : null,
            country:
                propRes.RegistrationMandFields?.country !== undefined ? propRes.RegistrationMandFields.country : null,
            email: propRes.RegistrationMandFields?.email !== undefined ? propRes.RegistrationMandFields.email : null,
            firstName:
                propRes.RegistrationMandFields?.firstName !== undefined
                    ? propRes.RegistrationMandFields.firstName
                    : null,
            gender: propRes.RegistrationMandFields?.gender !== undefined ? propRes.RegistrationMandFields.gender : null,
            lastName:
                propRes.RegistrationMandFields?.lastName !== undefined ? propRes.RegistrationMandFields.lastName : null,
            phone: propRes.RegistrationMandFields?.phone !== undefined ? propRes.RegistrationMandFields.phone : null,
            street: propRes.RegistrationMandFields?.street !== undefined ? propRes.RegistrationMandFields.street : null,
            zip: propRes.RegistrationMandFields?.zip !== undefined ? propRes.RegistrationMandFields.zip : null,
            insuranceNr:
                propRes.RegistrationMandFields?.insuranceNr !== undefined
                    ? propRes.RegistrationMandFields.insuranceNr
                    : null,
            mobilePhone:
                propRes.RegistrationMandFields?.mobilePhone !== undefined
                    ? propRes.RegistrationMandFields.mobilePhone
                    : null,
            academicTitle:
                propRes.RegistrationMandFields?.academicTitle !== undefined
                    ? propRes.RegistrationMandFields.academicTitle
                    : null,
            insuranceName:
                propRes.RegistrationMandFields?.insuranceName !== undefined
                    ? propRes.RegistrationMandFields.insuranceName
                    : null,
            language:
                propRes.RegistrationMandFields?.language !== undefined ? propRes.RegistrationMandFields.language : null,
            privacyTerms:
                propRes.RegistrationMandFields?.privacyTerms !== undefined
                    ? propRes.RegistrationMandFields.privacyTerms
                    : null,
        },
        RegistrationVisibleFields: {
            birthDate:
                propRes.RegistrationVisibleFields?.birthDate !== undefined
                    ? propRes.RegistrationVisibleFields.birthDate
                    : null,
            city: propRes.RegistrationVisibleFields?.city !== undefined ? propRes.RegistrationVisibleFields.city : null,
            country:
                propRes.RegistrationVisibleFields?.country !== undefined
                    ? propRes.RegistrationVisibleFields.country
                    : null,
            email:
                propRes.RegistrationVisibleFields?.email !== undefined ? propRes.RegistrationVisibleFields.email : null,
            firstName:
                propRes.RegistrationVisibleFields?.firstName !== undefined
                    ? propRes.RegistrationVisibleFields.firstName
                    : null,
            gender:
                propRes.RegistrationVisibleFields?.gender !== undefined
                    ? propRes.RegistrationVisibleFields.gender
                    : null,
            lastName:
                propRes.RegistrationVisibleFields?.lastName !== undefined
                    ? propRes.RegistrationVisibleFields.lastName
                    : null,
            phone:
                propRes.RegistrationVisibleFields?.phone !== undefined ? propRes.RegistrationVisibleFields.phone : null,
            street:
                propRes.RegistrationVisibleFields?.street !== undefined
                    ? propRes.RegistrationVisibleFields.street
                    : null,
            zip: propRes.RegistrationVisibleFields?.zip !== undefined ? propRes.RegistrationVisibleFields.zip : null,
            insuranceNr:
                propRes.RegistrationVisibleFields?.insuranceNr !== undefined
                    ? propRes.RegistrationVisibleFields.insuranceNr
                    : null,
            mobilePhone:
                propRes.RegistrationVisibleFields?.mobilePhone !== undefined
                    ? propRes.RegistrationVisibleFields.mobilePhone
                    : null,
            academicTitle:
                propRes.RegistrationVisibleFields?.academicTitle !== undefined
                    ? propRes.RegistrationVisibleFields.academicTitle
                    : null,
            insuranceName:
                propRes.RegistrationVisibleFields?.insuranceName !== undefined
                    ? propRes.RegistrationVisibleFields.insuranceName
                    : null,
            language:
                propRes.RegistrationVisibleFields?.language !== undefined
                    ? propRes.RegistrationVisibleFields.language
                    : null,
            privacyTerms:
                propRes.RegistrationVisibleFields?.privacyTerms !== undefined
                    ? propRes.RegistrationVisibleFields.privacyTerms
                    : null,
        },
        RegistrationReadOnlyFields: {
            birthDate:
                propRes.RegistrationReadOnlyFields?.birthDate !== undefined
                    ? propRes.RegistrationReadOnlyFields.birthDate
                    : null,
            city:
                propRes.RegistrationReadOnlyFields?.city !== undefined ? propRes.RegistrationReadOnlyFields.city : null,
            country:
                propRes.RegistrationReadOnlyFields?.country !== undefined
                    ? propRes.RegistrationReadOnlyFields.country
                    : null,
            email:
                propRes.RegistrationReadOnlyFields?.email !== undefined
                    ? propRes.RegistrationReadOnlyFields.email
                    : null,
            firstName:
                propRes.RegistrationReadOnlyFields?.firstName !== undefined
                    ? propRes.RegistrationReadOnlyFields.firstName
                    : null,
            gender:
                propRes.RegistrationReadOnlyFields?.gender !== undefined
                    ? propRes.RegistrationReadOnlyFields.gender
                    : null,
            lastName:
                propRes.RegistrationReadOnlyFields?.lastName !== undefined
                    ? propRes.RegistrationReadOnlyFields.lastName
                    : null,
            phone:
                propRes.RegistrationReadOnlyFields?.phone !== undefined
                    ? propRes.RegistrationReadOnlyFields.phone
                    : null,
            street:
                propRes.RegistrationReadOnlyFields?.street !== undefined
                    ? propRes.RegistrationReadOnlyFields.street
                    : null,
            zip: propRes.RegistrationReadOnlyFields?.zip !== undefined ? propRes.RegistrationReadOnlyFields.zip : null,
            insuranceNr:
                propRes.RegistrationReadOnlyFields?.insuranceNr !== undefined
                    ? propRes.RegistrationReadOnlyFields.insuranceNr
                    : null,
            mobilePhone:
                propRes.RegistrationReadOnlyFields?.mobilePhone !== undefined
                    ? propRes.RegistrationReadOnlyFields.mobilePhone
                    : null,
            academicTitle:
                propRes.RegistrationReadOnlyFields?.academicTitle !== undefined
                    ? propRes.RegistrationReadOnlyFields.academicTitle
                    : null,
            insuranceName:
                propRes.RegistrationReadOnlyFields?.insuranceName !== undefined
                    ? propRes.RegistrationReadOnlyFields.insuranceName
                    : null,
            language:
                propRes.RegistrationReadOnlyFields?.language !== undefined
                    ? propRes.RegistrationReadOnlyFields.language
                    : null,
        },
        allowAccountAfterAppointment: propRes.allowAccountAfterAppointment,
        allowAccountCreateDirect: propRes.allowAccountCreateDirect,
        allowAppointments: propRes.allowAppointments,
        allowAppointmentsWithoutLogin: propRes.allowAppointmentsWithoutLogin,
        allowDirectAppointments: propRes.allowDirectAppointments,
        allowDirectAppointmentsWithoutLogin: propRes.allowDirectAppointmentsWithoutLogin,
        allowLogin: propRes.allowLogin,
        allowVideo: propRes.allowVideo,
        allowVideoWithoutLogin: propRes.allowVideoWithoutLogin,
        allowAccountInAnmeldeworkflow: propRes.allowAccountInAnmeldeworkflow,
        brandingBytes: propRes.brandingBytes,
        brandigType: propRes.brandigType,
        extraLinks: propRes.extraLinks,
        customTexts: propRes.customTexts,
        showMobileQR: propRes.showMobileQR,
        jitsiDomain: propRes.jitsiDomain,
        jitsiOptions: propRes.jitsiOptions,
        impressum: propRes.impressum,
        anmeldeContexts: propRes.anmeldeContexts,
        countDaysToVisualize: propRes.countDaysToVisualize,
        extendedPhysicians: propRes.extendedPhysicians,
        allowChat: propRes.allowChat,
        googleAnalyticsID: propRes.googleAnalyticsID,
        tileTexts: propRes.tileTexts,
        smtpUser: propRes.smtpUser,
        possibleCountries: propRes.possibleCountries,
    };

    return appProps;
};

export const setDataFromAppProperties = (propRes: any, apdispatch: any) => {
    const appProps = getDataFromAppProperties(propRes);

    apdispatch({
        type: 'SETAPPPROPS',
        ...appProps,
    });
};

export const getFullOTPAuthorization = (prefix: string, otp: string, userId: string | number, t0: string) => {
    let _userId = userId;
    if (typeof userId === 'number') {
        _userId = userId.toString();
    }

    let _prefix = '';
    if (prefix.length > 0) {
        _prefix = prefix + ' ';
    }

    const otpAuth = _prefix + otp + '&userId=' + _userId + '&t0=' + t0;
    return otpAuth;
};

export const isDEVMode = () => {
    if (process.env.NODE_ENV === 'development' || window.location.hostname === 'localhost') {
        return true;
    }
};
