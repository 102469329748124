import { useContext } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import LoginContext from '../../context/loginContext';
import { Paths } from '../../Routes';
import StyledButton from './StyledButton';

interface IEncounterTitle {
    smallScreen?: boolean;
}

const EncounterTitle = (props: IEncounterTitle) => {
    const { state, dispatch } = useContext(LoginContext);
    const navigate = useNavigate();
    const resetEncounter = () => {
        dispatch({
            type: 'SETENCOUNTER',
            encounterId: '',
            encounterTitle: '',
        });
        navigate(Paths.TERMINE);
    };

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="EncounterTitleTooltip">Terminauswahl aufheben</Tooltip>}
        >
            <StyledButton
                variant="secondary"
                paddingLeft="2px"
                onClick={resetEncounter}
                style={{ fontSize: props.smallScreen ? '0.6rem' : '1rem' }}
            >
                <Badge>
                    <IoIosCloseCircleOutline size={20} />
                </Badge>
                {state.encounterTitle}
            </StyledButton>
        </OverlayTrigger>
    );
};

export default EncounterTitle;
